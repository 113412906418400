<template>
  <el-dialog title="小程序码"
             :modal-append-to-body="false"
             :visible.sync="dialogVisible"
             width="30%">
    <div style="width:100%;height:auto">
      <el-image :src="img"
                fit="fill"
                style="width: 100%;">
        <div slot="placeholder"
             class="image-slot">
          加载中<span class="dot">...</span>
        </div>
      </el-image>
    </div>
    <slot name="footer"></slot>
    <span slot="footer"
          class="dialog-footer">
      <template v-if="path">
        <p>appid:wxc9590ed0fcffe71b</p>
        <p>{{pathText || '内部路径'}}:/{{path}}</p>
      </template>
    </span>
  </el-dialog>
</template>

<script>
import { createMpCode } from '@/api/index'
export default {
  name: '',
  props: ['title'],
  data () {
    return {
      dialogVisible: false,
      img: '',
      row: null,
      path: null,
      pathText: null
    }
  },
  components: {
  },
  methods: {
    async createMpCode (row, path, pathText) {
      this.img = ''
      this.dialogVisible = true
      this.path = path
      this.pathText = pathText
      const res = await createMpCode({
        page: row.page,
        scene: row.scene
      })
      if (res.meta.code === 0) {
        this.img = res.data.qrcode.img_data.url
      } else {
        this.$message.error(res.meta.msg)
      }
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
